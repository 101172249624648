import styled from 'styled-components';

import Container from 'components/common/container';
import { cookieOffset } from 'components/common/cookie-banner/styles';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

import { IProductDetailsTitleProps } from './types';

interface IWrapperProps {
  isOpen: boolean;
  cookieAccepted: boolean;
}

interface IProductWrapperProps {
  productCount: number;
}

export const StyledContainer = styled(Container)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});

export const Wrapper = styled.div<IWrapperProps>(
  ({ isOpen, cookieAccepted }) => ({
    backgroundColor: theme.colors.offWhite,
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    bottom: 0,
    opacity: isOpen ? 1 : 0,
    left: 0,
    right: 0,
    maxWidth: '100vw',
    padding: `24px var(--space-l) ${cookieOffset(
      cookieAccepted,
      'var(--space-l)',
    )} var(--space-l)`,
    position: 'fixed',
    transform: `translateY(${isOpen ? 0 : '100%'})`,
    transition: `all ${theme.transitionSpeeds.normal}s ease-out`,
    willChange: 'transform',

    [tablet]: {
      position: 'absolute',
      borderRadius: 'var(--space-m-l)',
      padding: 'var(--space-m-l)',
      left: 'auto',
      right: 'var(--site-gutter)',
      maxWidth: '375px',
      top: 'var(--space-2xs)',
      bottom: 'auto',
      transform: `translateY(${isOpen ? 'var(--space-l)' : '-100%'})`,
      width: '100%',
    },
  }),
);

export const ProductDetails = styled.div<IProductWrapperProps>(
  ({ productCount }) => ({
    display: 'flex',
    marginBottom: '20px',
    flexDirection: productCount > 1 ? 'column' : 'row',
    width: productCount > 1 ? '48%' : '100%',
  }),
);

export const ProductWrapper = styled.div<IProductWrapperProps>(
  ({ productCount }) => ({
    display: 'flex',
    flexDirection: productCount > 1 ? 'row' : 'column',
    justifyContent: 'space-between',
    marginTop: productCount > 1 ? 10 : 20,
  }),
);

export const ProductImageWrapper = styled.div<IProductWrapperProps>(
  ({ productCount }) => ({
    flex: `0 1 ${productCount === 1 ? '150px' : '1'}`,
    marginRight: '16px',
    marginBottom: '16px',
  }),
);

export const ProductDetailsTitle = styled.div<IProductDetailsTitleProps>`
  font-family: ${theme.fonts.body};
  font-size: var(--step-0);
  font-weight: ${theme.weights.bold};
  margin-bottom: ${(props) =>
    !props.marginBottom ? '20px' : `${props.marginBottom}px`};
`;

export const ProductDetailsPrice = styled.div({
  color: theme.colors.darkGreen,
  fontWeight: theme.weights.bold,
});
